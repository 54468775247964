import { defineStore } from "pinia";

type Filter = {
  attribute: string | string[];
  filterType: "hierarchical" | "facet" | "range";
  taxonomy?: string;
};

type FilterRegistry = {
  filters: Array<Filter>;
};

const productListFilters: Filter[] = [
  {
    attribute: "v.lvl0",
    filterType: "hierarchical",
  },
  {
    attribute: "a",
    filterType: "facet",
    taxonomy: "manufacturers",
  },
  {
    attribute: "b",
    filterType: "facet",
    taxonomy: "sex",
  },
  {
    attribute: "c.price_min",
    filterType: "range",
  },
  {
    attribute: "d",
    filterType: "facet",
    taxonomy: "palette",
  },
  {
    attribute: "e",
    filterType: "facet",
    taxonomy: "size",
  },
  {
    attribute: "f",
    filterType: "facet",
  },
  {
    attribute: "h.price_min",
    filterType: "range",
  },
  {
    attribute: "i",
    filterType: "facet",
  },
  {
    attribute: "j",
    filterType: "facet",
  },
  {
    attribute: "k",
    filterType: "facet",
  },
  {
    attribute: "l",
    filterType: "facet",
  },
  {
    attribute: "m_n",
    filterType: "range",
  },
  {
    attribute: "n_n",
    filterType: "range",
  },
  {
    attribute: "o",
    filterType: "facet",
  },
  {
    attribute: "p",
    filterType: "range",
  },
  {
    attribute: "q",
    filterType: "facet",
  },
  {
    attribute: "r_a",
    filterType: "facet",
  },
  {
    attribute: "s_a",
    filterType: "facet",
  },
  {
    attribute: "t",
    filterType: "facet",
  },
  {
    attribute: "u",
    filterType: "facet",
  },
  {
    attribute: "w",
    filterType: "facet",
  },
  {
    attribute: "x",
    filterType: "facet",
  },
  {
    attribute: "y_a",
    filterType: "facet",
  },
  {
    attribute: "z",
    filterType: "facet",
  },
  {
    attribute: "aa",
    filterType: "facet",
  },
  {
    attribute: "ab",
    filterType: "facet",
  },
];

const contentElementListFilters: Filter[] = [
  {
    attribute: "a",
    filterType: "facet",
    taxonomy: "ce_category",
  },
  {
    attribute: "b",
    filterType: "facet",
    taxonomy: "ce_activity",
  },
  {
    attribute: "c",
    filterType: "facet",
    taxonomy: "ce_article_type",
  },
  {
    attribute: "d",
    filterType: "facet",
    taxonomy: "ce_triptip_location",
  },
  {
    attribute: "e",
    filterType: "facet",
    taxonomy: "ce_triptip_tag",
  },
  {
    attribute: "f",
    filterType: "facet",
  },
  {
    attribute: "g",
    filterType: "facet",
  },
  {
    attribute: "h",
    filterType: "range",
  },
  {
    attribute: "i",
    filterType: "facet",
  },
  {
    attribute: "j",
    filterType: "facet",
    taxonomy: "manufacturers",
  },
];

export const useFilterRegistry = defineStore("filterRegistry", {
  getters: {
    getFilterByAttribute: ({ filters }) => {
      return (attribute: string) =>
        filters.find((filter) => filter.attribute === attribute);
    },
    getFilterType: (state) => {
      return (attribute: string) =>
        state.getFilterByAttribute(attribute).filterType;
    },
    getTaxonomy: (state) => {
      return (attribute: string) =>
        state.getFilterByAttribute(attribute).taxonomy ?? null;
    },
    taxonomies: (state) => {
      return state.filters
        .map((filter) => filter.taxonomy ?? null)
        .filter(
          (taxonomy, index, array) =>
            taxonomy && array.indexOf(taxonomy) === index,
        );
    },
    attributes: (state) => {
      return state.filters
        .map(({ attribute }) => attribute)
        .reduce((result, attribute) => {
          if (typeof attribute === "string") {
            // Facet & range
            result.push(attribute);
          } else if (Array.isArray(attribute)) {
            // Hierarchical menu
            result.push(...JSON.parse(JSON.stringify(attribute)));
          }

          return result;
        }, []);
    },
    productListFilters: () => productListFilters,
    contentElementListFilters: () => contentElementListFilters,
  },
  state: (): FilterRegistry => ({
    filters: [],
  }),
});
